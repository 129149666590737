<template>
    <div>{{id}}
        <vue-good-table
        :columns="columns"
        :rows="rows"
        @change="(pl) => $emit('change', pl)"
        theme="deliveryboosting">
            <template slot="table-row" slot-scope="props">
                <span class="flex justify-end" v-if="props.column.field == 'after'">
                    <a @click="deleteModal = props.row.uuid" class="mr-4 hover:text-primary cursor-pointer"><icon-trash class="h-6"/></a>
                    <a @click="$router.push( { name: 'dashboard-food-detail', params: { productId: props.row.uuid } } )" class="hover:text-primary cursor-pointer"><icon-cog class="h-6"/></a>
                    <transition name="slide-fade">
                    <delete-product v-if="deleteModal === props.row.uuid" @close="deleteModal = null" :uuid="props.row.uuid" :title="props.row.title"/>
                    </transition>
                </span>
                <span v-if="props.column.field == 'id'">
                    <span class="text-gray-600 bg-opacity-25 py-2 px-5 font-bold transparent rounded" :class="{'bg-primary': props.row.article, 'bg-gray-500': !props.row.article}">
                    {{props.row.id}}
                    </span>
                </span>
                <span v-else-if="props.column.field == 'status'">
                    <badge :class="{'bg-purple': props.row.status == 'TIMED', 'bg-green': props.row.status == 'ONLINE', 'bg-red text-red': props.row.status == 'OFFLINE'}" settings="transparent">
                    {{ $t(`dashboard.status_${props.row.status}`) }}
                    </badge>
                    <strong class="text-black text-sm" v-if="props.row.status == 'TIMED'"><br/>{{(new Date(props.row.timeFrom).getDate())}}{{'.'}}{{(new Date(props.row.timeFrom).getMonth()+1)}}<strong v-if="!((new Date(props.row.timeFrom).getDate() === new Date(props.row.timeTo).getDate()) && (new Date(props.row.timeFrom).getMonth() === new Date(props.row.timeTo).getMonth()))"> {{'-'}} {{(new Date(props.row.timeTo).getDate())}}{{'.'}}{{(new Date(props.row.timeTo).getMonth()+1)}}</strong></strong>
                </span>
                <span v-else-if="props.column.field == 'surcharge'">
                    <span v-if="props.row.surcharge.amount" class="text-primary font-bold">
                    {{ $n(props.row.surcharge.amount/100, 'currency')}}
                    </span>
                </span>
                <span v-else-if="props.column.field == 'surcharge2'">
                    <span v-if="props.row.surcharge2.amount" class="text-primary font-bold">
                    {{ $n(props.row.surcharge2.amount/100, 'currency')}}
                    </span>
                </span>
                <span v-else-if="props.column.field == 'title'">
                    <span class="text-gray-600 font-bold">
                    {{props.row.title}}
                    </span>
                </span>
                <span v-else>
                {{props.formattedRow[props.column.field]}}
                </span>
            </template>
        </vue-good-table>
        <div class="mt-1 mb-1" v-if="!noArticleNumber && lengthNoPlacementNumber > 0 && atLeastOne">
            <div class="float-left items-center mt-2 ml-3    h-6 w-7 bg-gray-500 bg-opacity-30 rounded-sm"></div>
            <div class="float-left mt-2 ml-1 text-black font-semibold">{{$t('dashboard.placementNumber')}}</div>
            <div class="float-left items-center mt-2 ml-8 h-6 w-7 bg-primary bg-opacity-30 rounded-sm">
            <div class="float-left ml-8 text-black font-semibold">{{$t('dashboard.articleNumber')}}</div>
        </div>
        </div>
    </div>
</template>

<script>
// https://xaksis.github.io/vue-good-table/guide/style-configuration/#polar-bear
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import IconTrash from '../icons/IconTrash.vue'
import IconCog from '../icons/IconCog.vue'
import Badge from '../ui/Badge.vue'
import DeleteProduct from '@/components/modals/DeleteProduct.vue'

export default {
    components: {
        VueGoodTable,
        IconTrash,
        IconCog,
        Badge,
        DeleteProduct
    },
    props: {
        products: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            columns: [],
            deleteModal: null,
            number: 'Nr.'
        }
    },
    computed: {
        rows () {
            return this.products.map(p => ({
                uuid: p.uuid,
                id: p.no || p.articleNumber,
                title: p.title,
                description: p.description,
                surcharge: (p.sizes[0] || { getPriceByMethod: () => {} }).getPriceByMethod('delivery') || {},
                surcharge2: (p.sizes[0] || { getPriceByMethod: () => {} }).getPriceByMethod('pick-up') || {},
                status: p.availability.state,
                timeFrom: p.availability.stateTimeFrameFrom,
                timeTo: p.availability.stateTimeFrameTo,
                interaction: '',
                article: (typeof p.no === 'undefined' || p.no === '') && !((typeof p.articleNumber === 'undefined' || p.articleNumber === '') && (typeof p.no === 'undefined' || p.no === ''))
            }))
        },
        lengthNoPlacementNumber () {
            return this.products.map(p => p.no).filter(no => no === '').length
        },
        noArticleNumber () {
            return this.products.length === this.products.map(p => p.articleNumber).filter(a => a === '' || typeof a === 'undefined').length
        },
        atLeastOne () {
            return this.products.map(p => (typeof p.no === 'undefined' || p.no === '') && !((typeof p.articleNumber === 'undefined' || p.articleNumber === '') && (typeof p.no === 'undefined' || p.no === ''))).includes(true)
        },
        // eslint-disable-next-line vue/return-in-computed-property
        addColumns () {
            if (this.lengthNoPlacementNumber > 0 && this.atLeastOne) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.columns = [
                    { label: 'Nr. / ArtikelNr.', field: 'id' }, { label: this.$t('dashboard.title'), field: 'title' }, { label: this.$t('dashboard.description'), field: 'description', sortable: false }, { label: this.$t('dashboard.delivery_price'), field: 'surcharge', thClass: 'text-center', tdClass: 'text-center' }, { label: this.$t('dashboard.pick-up_price'), field: 'surcharge2', thClass: 'text-center', tdClass: 'text-center' }, { label: this.$t('dashboard.status'), field: 'status', thClass: 'text-center', tdClass: 'text-center' }, { label: '', field: 'after', sortable: false }
                ]
            } else {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.columns = [
                    { label: 'Nr.', field: 'id' }, { label: this.$t('dashboard.title'), field: 'title' }, { label: this.$t('dashboard.description'), field: 'description', sortable: false }, { label: this.$t('dashboard.delivery_price'), field: 'surcharge', thClass: 'text-center', tdClass: 'text-center' }, { label: this.$t('dashboard.pick-up_price'), field: 'surcharge2', thClass: 'text-center', tdClass: 'text-center' }, { label: this.$t('dashboard.status'), field: 'status', thClass: 'text-center', tdClass: 'text-center' }, { label: '', field: 'after', sortable: false }
                ]
            }
        }
    },
    watch: {
        lengthNoPlacementNumber: {
            immediate: true,
            handler () {
                if (this.lengthNoPlacementNumber > 0) {
                    this.number = 'Nr./ArtikelNr.'
                }
            }
        }
    },
    created () {
        // eslint-disable-next-line no-unused-expressions
        this.addColumns
    }
}
</script>

<style>
    .vgt-inner-wrap {
        @apply shadow-none;
    }
    .deliveryboosting thead tr th {
        background: transparent !important;
        @apply text-gray-500;
    }
    .deliveryboosting, .deliveryboosting tr th, .deliveryboosting tr td {
        border: none !important;
    }
    .deliveryboosting thead tr, .deliveryboosting tbody tr {
        @apply border-b;
    }
    .deliveryboosting tbody tr td {
        @apply py-6 align-middle;
        @apply text-gray-500;
    }
    .slide-fade-enter-active {
    transition: all .5s ease;
    }
    .slide-fade-leave-active {
    transition: all .5s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(100px);
    opacity: 0;
    }
</style>
