<template>
    <dashboard-sidebar>
        <template #content>
            <title-bar>
                <template #title>
                    {{ category.title }}
                </template>
                <template #interactive>
                    <div class="mt-4 flex md:mt-0 md:ml-4">
                        <button-a @click.native="$router.back()" settings="bg-primary cursor-pointer">
                            <icon-chevron-left height="24"/>
                            {{ $t('dashboard.back') }}
                        </button-a>
                    </div>
                </template>
            </title-bar>
            <card-box>
                <div class="flex items-center justify-between mb-10">
                    <h4 class="mb-5">{{ $t('dashboard.meals') }}</h4>
                    <button-b @click.native="addProduct" class="bg-primary">{{ $t('dashboard.new_meal') }}</button-b>
                </div>
                <table-food :products="products" @change="move"></table-food>
            </card-box>
        </template>
    </dashboard-sidebar>
</template>

<script>
import DashboardSidebar from '@/components/layout/DashboardSidebar.vue'
import TitleBar from '../../components/dashboard/TitleBar.vue'
import ButtonB from '../../components/ui/ButtonB.vue'
import ButtonA from '../../components/ui/ButtonA.vue'
import CardBox from '../../components/dashboard/CardBox.vue'
import TableFood from '../../components/dashboard/TableFood.vue'
import IconChevronLeft from '../../components/icons/IconChevronLeft.vue'
export default {
    name: 'Categories',
    components: {
        DashboardSidebar,
        TitleBar,
        ButtonB,
        ButtonA,
        CardBox,
        TableFood,
        IconChevronLeft
    },
    computed: {
        category () {
            if (this.$route.params.categoryId === 'all') {
                return {
                    uuid: 'all',
                    title: this.$t('dashboard.all_products')
                }
            }
            return this.$store.state.catalog.getCategoryByUuid(this.$route.params.categoryId) || {}
        },
        products: {
            get () {
                if (this.$route.params.categoryId === 'all') {
                    return this.$store.state.catalog.products || []
                }
                return this.$store.state.catalog.getProducts((this.category).products || [])
            }
        }
    },
    methods: {
        addProduct () {
            if (this.$route.params.categoryId === 'all') {
                this.$router.push({ name: 'dashboard-food-detail', params: { productId: this.$store.state.catalog.addProduct().uuid } })
            } else {
                const product = this.$store.state.catalog.addProduct()
                this.$router.push({ name: 'dashboard-food-detail', params: { productId: this.category.addProduct(product.uuid) } })
            }
        },
        async move (pl) {
            const products = this.category.products
            const oldIndex = pl.moved.oldIndex - 1
            const newIndex = pl.moved.newIndex - 1
            console.log('prod:', products, products.length)
            console.log('old: ', oldIndex)
            console.log('new: ', newIndex)
            const temp = products[oldIndex]
            if (oldIndex > newIndex) {
                for (var i = oldIndex; i >= newIndex; i--) {
                    products[i] = products[i - 1]
                }
            } else if (newIndex > oldIndex) {
                for (var o = oldIndex; o < newIndex; o++) {
                    products[o] = products[o + 1]
                }
            }
            products[newIndex] = temp
            this.category.products = products
            await this.$store.state.catalog.commit()
        }
    }
}
</script>
