<template>
    <modal @close="close" class="z-100">
        <template #title>{{ $t('dashboard.popup_delete_product1') }}<span class="text-primary">{{title}}</span>{{ $t('dashboard.popup_delete_product2') }}</template>
        <template #body>
            <button-b :loading="loading" @click.native="confirmDeletion" class="bg-primary">{{ $t('dashboard.delete') }}</button-b>
            <button-b @click.native="close" class="bg-gray-600 ml-2" style="float: center">{{ $t('dashboard.cancel') }}</button-b>
        </template>
    </modal>
</template>

<script>
import ButtonB from '../../components/ui/ButtonB.vue'
import Modal from '../../components/dashboard/Modal.vue'

export default {
    name: 'DashboardSettings',
    data () {
        return {
            valueStatus: null,
            loading: false
        }
    },
    props: {
        uuid: {
            type: String
        },
        title: {
            type: String
        }
    },
    methods: {
        close () {
            this.$emit('close')
        },
        async confirmDeletion () {
            this.loading = true
            this.$store.state.catalog.removeProduct(this.uuid)
            await this.$store.state.catalog.commit()
            this.loading = false
            this.close()
        }
    },
    components: {
        ButtonB,
        Modal

    }
}
</script>
